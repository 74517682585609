import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {NikeApp} from "@nike/nike-design-system-icons";
import {NavItem, setActiveValueInNavItems, VerticalNavbar} from "@nike/eds";


export function SideBar({roles}) {
    const navigate = useNavigate()
    const location = useLocation()
    const routingMap = new Map<string, string>([
        ["1", '/inventory-overview'],
        ["2", '/request-overview'],
        ["3", '/simulation/raw-event']
    ]);
    const [items, setItems] = useState<NavItem[]>([
        {
            id: '1',
            icon: 'Journal',
            label: 'Inventory overview',
            active: false
        },
        {
            id: '2',
            icon: 'Activity',
            label: 'Request overview',
            active: false
        },
        {
            id: '3',
            icon: 'Play',
            label: 'Simulate',
            active: false
        }
    ])

    const handleClick = (item: { id: string }) => {
        navigate(routingMap.get(item.id))
    }

    useEffect(() => {

    }, [roles])

    useEffect(() => {
            changeIconBasedOnLocation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, location])

    // check location on items id if location has id in url
    const changeIconBasedOnLocation = () => {
        for (const item of items) {
            const route = routingMap.get(item.id);
            if (location.pathname.includes(route)) {
                if (!item.active) {
                    setActiveIcon(item.id)
                }
            }
        }
    }

    const setActiveIcon = (id: string) => {
        const newItems = setActiveValueInNavItems(id, items)
        if (newItems) { setItems(newItems as NavItem[]) }
    }

    return (
        <VerticalNavbar
            appName={'Customs Inventory'}
            items={items}
            navLogoSlot={
                <Link reloadDocument to='/' aria-label='logo-link'>
                    <NikeApp
                        title='Nike logo'
                        color={'white'}
                        width={'56px'}
                        height={'64px'}
                        style={{marginTop: '6px'}}
                    />
                </Link>
            }
            onNavigate={handleClick}
        />
    )

}
